import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/sante/home"
import SelectTerr from "../components/sante/select"
import AppCore from "../components/sante/appCore"
import imgsante from "../images/sante.svg"
const ComparateurSante = () => {
  const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)

  return (
    <Layout
      title="Les besoins potentiels en santé"
      headerContainer={headerContainer}
      img={imgsante}
    >
      <SEO title="Baromètre Compas - Besoins potentiels en santé" />
      <Home
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
      />
      <SelectTerr
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <AppCore
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default ComparateurSante
