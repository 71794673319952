import React from "react";
import {
  Flex,
  Text,
  Box,
  Stack,
  Icon,
  Link,
} from "@chakra-ui/core";

import image from "../../images/43122.jpg";
import startImg from "../../images/image_CompaszOOm25-22Avril2020.png";

export default function Home ()  {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex flex={10} direction="column">
          <Stack spacing={5} >
            <Text fontSize="lg" fontWeight="600">
              Repérer les besoins en santé, dans un contexte de crise sanitaire.
            </Text>
            <Text>
              La crise sanitaire que nous vivons actuellement révèle, sur
              l’ensemble du territoire national, des besoins en matière de santé
              pour l’ensemble de la population. Pour autant, là encore, les
              inégalités sociales opèrent puisque les publics les plus précaires
              subissent davantage les effets de la crise du Covid-19.
            </Text>

            <Text>
              Le{" "}
              <Link href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </Link>{" "}
              propose un indice agrégé (
              <span style={{ fontWeight: "600" }}>baromètre©Compas</span>)
              s’appuyant sur les différents travaux réalisés dans ce domaine
              afin de valider des déterminants sociaux de santé pertinents,
              déclinables pour chaque commune et ses quartiers. Cet indicateur
              se veut opérationnel en définissant des zones d’actions
              prioritaires et pourra être croisé avec des projections de
              population <span style={{ fontWeight: "600" }}>©Compas</span>,
              l’offre de soins présente sur la commune ou l’intercommunalité et
              l’accessibilité aux soins.
            </Text>
          </Stack>
          
        </Flex>
        <Flex flex={6}  direction="column">
          <Flex alignItems="center" m={0}>
            <img
              src={image}
              width={"50%"}
              alt="lecture"
              style={{ margin: "auto" }}
            ></img>
          </Flex>

          <Box
            m={5}
            as="a"
            rel="noopener noreferrer"
            title="Compas zOOm n°25"
            href="http://www.lecompas.fr/doc/CompaszOOm25-22Avril2020.pdf"
            target="_blank"
          >
            <Text textAlign="center" mb={2}>
              Cliquez et Consultez
              <br /> le{" "}
              <span style={{ fontWeight: "600" }}>numéro spécial</span> Compas
              zOOm consacré aux besoins potentiels en santé
              <Icon name="download"></Icon>
            </Text>

            <img
              src={startImg}
              alt="logo Compas Zoom"
              style={{margin:"auto"}}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
